import { UncontrolledCarousel } from 'reactstrap';

const SliderCarousel = props => {
    const items = [
        {
            src: 'static/img/Banner01L-min.png',
            altText: '',
            caption: '',
            header: ''
        },
        {
            src: 'static/img/Banner02L-min.png',
            altText: '',
            caption: '',
            header: ''
        },
        {
            src: 'static/img/Banner03L-min.png',
            altText: '',
            caption: '',
            header: ''
        }
    ];

    return (
        <div>
            <UncontrolledCarousel items={items} className="pt-5"/>
        </div>
    );
};

export default SliderCarousel;
