import Layout                from '../components/Layout/Layout.js';
import Search                from '../components/Search/Search';
import SliderCarousel        from '../components/SliderCarousel'
import MultipleItemCarousel  from '../components/MultipleItemCarousel'
import MapProperties         from '../components/Map/MapProperties';
import {Link}                from '../routes'
import slug                  from '../helpers/slug';

import'isomorphic-fetch';
export default class extends React.Component  {

    state = {
        dropdownOpen: true,
        propertiesAll: null,
        properties   : null,
    };
    toggle = this.toggle.bind(this)
    subtitle = " Inicio ";

    constructor(props) {
        super(props);       
        (async () => {
            try {
                let request        = await fetch(`${process.env.API_HOST}properties/lastAdded?limit=10`);
                let requestAll     = await fetch(`${process.env.API_HOST}properties/points`);
                let propertiesAll  = await requestAll.json()
                let properties     = await request.json();
                this.setState({properties, propertiesAll});
               
            } catch(e) {
                // console.log('error')
            }
        })();
    }

    componentDidMount() {
        const scrollToTop = () => {
            const c = document.documentElement.scrollTop || document.body.scrollTop;
            if (c > 0) {
                window.requestAnimationFrame(scrollToTop);
                window.scrollTo(0, c - c / 8);
            }
        };
        scrollToTop();
    }
    
    toggle() {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        });
    }

    render() {
        const {properties, propertiesAll} = this.state;
        const coords  = {"lat" : 20.67329,  "long" : -103.3633396};
        const sizeMap = {"width" : "100%", "height" : "100%"}
        return <Layout subtitle={" "}>
            <SliderCarousel></SliderCarousel>

            <div className="creative-lp">
                <div className="flex-center mask  intro-3">
                    <div className="container my-3 row center">
                        <div className="white-text ml-4 py-1 col-12">
                            <h3>
                                ¡Conoce todas las propiedades que tenemos en venta y renta!
                                <Link prefetch route="propiedades" params={{
                                        page: 1,
                                        slugType: slug('todo')
                                    }}>
                                    <a href="" className="orage-text"> &nbsp; Ir al catálogo </a>
                                </Link>
                            </h3>
                        </div>
                    </div>
                </div>
            </div>
            { propertiesAll != null && (
                <div className="col h-500 px-0">          
                    <MapProperties
                        points  = {propertiesAll.data.locations}
                        coords  = {coords}
                        zoomMap = {13}
                        size    = {sizeMap}
                    />
                </div>
            )}
            { properties  != null&& (
                <MultipleItemCarousel 
                    properties={properties.data.properties}
                />
            )}
                
                

            <style jsx>{`
                .creative-lp .intro-3 {
                    background-image: -webkit-linear-gradient(330deg,  #8cc43f,#77c114,#009345,#89c540 );
                    background-image: -o-linear-gradient(330deg,  #8cc43f,#77c114,#009345,#89c540);
                    background-image: linear-gradient(120deg,  #8cc43f,#77c114,#009345,#89c540 );
                    background-repeat: no-repeat;
                    -webkit-background-size: 800% 400%;
                    background-size: 800% 400%;
                    -webkit-animation-name: introAnimation;
                    animation-name: introAnimation;
                    -webkit-animation-duration: 30s;
                    animation-duration: 30s;
                    -webkit-animation-timing-function: linear;
                    animation-timing-function: linear;
                    -webkit-animation-delay: 0;
                    animation-delay: 0;
                    -webkit-animation-iteration-count: infinite;
                    animation-iteration-count: infinite; 
                }

                @-webkit-keyframes introAnimation {
                  0% {
                    background-position: 0 50%; }
                  50% {
                    background-position: 100% 50%; }
                  100% {
                    background-position: 0 50%; } 
                }

                @keyframes introAnimation {
                  0% {
                    background-position: 0 50%; }
                  50% {
                    background-position: 100% 50%; }
                  100% {
                    background-position: 0 50%; } 
                }

                .creative-lp .intro-title {
                      font-size: 3rem;
                      line-height: 4rem; 
                }
                .creative-lp .streak.streak-md {
                    height: auto;
                }
                .ui.action.input:not([class*="left action"])>input:focus {
                    border-color: #ff9a24!important;
                }
                .ui.header>icon{
                    color: green!important;
                }
                .ui.header>content{
                    color: rgba(74, 71, 71, 0.85);
                }
                .orage-text
                {
                    color: #ffc865;
                }
                .h-500{
                    height: 500px;
                }

            `}</style>  
        </Layout>
    }
}


