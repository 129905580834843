import React from "react";

/*FontAwensome*/
import { library }         from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearchLocation} from '@fortawesome/pro-regular-svg-icons'
library.add(faSearchLocation);

/* Own components */
import AGREEMENT_TYPES from "../../constants/agreement_types";
import CATEGORIES 	   from "../../constants/category_constants";

import {
	Form,
} from 'semantic-ui-react';

import InputType     from "./InputType";
import InputMap      from "./InputMap"
import InputCategory from "./InputCategory";

class SearchFiltersForm extends React.Component {

	ParseIntIfNotUndefined = arg => arg == undefined || arg == null || typeof arg !== "string"
        ? null
		: parseInt(arg);
		
	constructor(props){
        super(props);
		
        this.state = {
            params : {
				type_id  : this.props.paramsSearch.type_id,
				category : this.ParseIntIfNotUndefined(this.props.paramsSearch.category),
            }
        };
    }


	render() {
		const props = this.props;
        const {
			type_id,
			category
		} = this.state.params;
		
		return <Form className="row col">
			<div className="col px-0">
				<InputMap
					updateGeolacation = {props.updateGeolacation}
				/>
			</div>	
			<InputType		    	 
				placeholder='Tipo de contrato' 
				search 
				selection 
				options={AGREEMENT_TYPES}
				className="col-lg-2 col-md-1 col px-0"
				updateType={props.updateType}
				defaultValue={type_id}
				/>
			<InputCategory 
				placeholder='Categoría' 
				search 
				selection 
				options={CATEGORIES}
				className="col-lg-2 col-md-1 col px-0"
				updateCategory={props.updateCategory}
				defaultValue={category}
				
			/>	    
			<style jsx>{`
				.ui.selection.dropdown {
					border-radius: 0!important;
				}  
			`}</style>
			<style global jsx>{` 
				.ui.selection.dropdown {
		
					border-radius: 0!important;
				}
				.ui.form input:not([type]), .ui.form input[type=text] {
					border-radius: 0!important;
				}
			`}</style>
		
		</Form>
	}
}

export default SearchFiltersForm;
