import React from "react"; 

/*FontAwensome*/
import { library }         from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExpandAlt,
         faVectorSquare
} from '@fortawesome/pro-light-svg-icons'
library.add(faExpandAlt,
            faVectorSquare);


import {Link} from '../../routes'
import slug   from '../../helpers/slug'

export class PropertyCardStyleThree extends React.Component {

    constructor(props){
        super(props);
        this.property = this.props.property;
    }

    renderElement(files){
        const url_img = `${process.env.API_HOST}image/`;
        if(files.length > 1)
            return `${url_img}${files[0].id}_sm`
        return  '/static/img/imagen_no_disponible.png'
    }

    render() {
        return <React.Fragment>
            {  this.property != undefined &&
                <div className="row col-12 pt-2">                      
               
                    <div className="col-6 box">
                        <img src={this.renderElement(this.property.files)}></img>
                    </div>
                    
                    <div className="col-6 pl-2 pr-0">
                        <p className="mini">
                            <b>{this.property.type.name}</b>
                        </p>
                        <p className="mini">{ parseInt(this.property.price).toLocaleString('en-US', {style: 'currency', currency: 'USD'})}MXN</p>
                       
                        <span className="fa fa-expand">
                            <FontAwesomeIcon 
                                icon={['fal','expand-alt']} 
                                className="mr-2 green-text"
                            /> Terreno: {this.property.ground} mts2
                        </span>
                        <br/>
                        <span className="fa fa-expand">
                            <FontAwesomeIcon 
                                icon={['fal','vector-square']} 
                                className="mr-2 green-text"
                            /> Construcción: {this.property.building} mts2
                        </span>
                    </div>
                    <hr className="border"/>
                    <div className="col-12 pl-2 pr-0 center">
                        <Link route="propiedad" params={{
                            slug: slug(this.property.name),
                            id: this.property.id
                        }}>
                          
                                VER MÁS
                           
                        </Link>
                    </div>
                </div>
            }
            <style  jsx>{`
                .mini{
                    margin: 0 0 1em!important;
                    line-height: 0.8295em!important;
                    font-size: 12px!important;
                }
                .box img{
                    width: 90px;
                    height: 90px;
                    display: flex!important;
                }
                @supports(object-fit: cover){
                    .box img{
                        width: 100%;
                        object-fit: cover;
                        object-position: center center;
                    }
                }
                .border{
                    border: #8cc43f solid 1px!important;
                    width: 100%;
                }
             
            `}</style>
        </React.Fragment>
    }
}

export default PropertyCardStyleThree;