import {Map, InfoWindow, Marker, GoogleApiWrapper} from 'google-maps-react';
import PropertyCardStyleThree  from '../../components/PropertiesCards/PropertyCardStyleThree';
export class MapProperties extends React.Component {
	constructor(props)
	{
		super(props);

		this.state = {
			showingInfoWindow	: false,
			lat					: null,
			lng					: null,
			activeMarker		: {},
			selectedPlace		: {},
			showInitial 		: false,
		};
	}
	

	geo = () => {
		const geolocation = {
			lat: null,
			lng: null,
		};

	  	if (navigator.geolocation) {
	    	navigator.geolocation.getCurrentPosition(function(position) {
		      	geolocation.lat = position.coords.latitude;
		        geolocation.lng = position.coords.longitude;
		    });
	  	}

	  	this.setState({
	  		lat : geolocation.lat,
	  		lng : geolocation.lng,

	  	});
	};

	onMapReady = (mapProps, map) => {
		this.map = map;
	  	window.onresize = () => {
	    	const currCenter = map.getCenter();
	    	this.props.google.maps.event.trigger(map, 'resize');
	    	map.setCenter(currCenter);
	  	};
	};

	onMarkerClick     = () => this.setState({ showingInfoWindow: true });
	onInfoWindowClose = () => this.setState({ showingInfoWindow: false });
	onMapClicked = () => {
		if (this.state.showingInfoWindow)
	    	this.setState({ showingInfoWindow: false });
	};
	

	onMarkerClick = (props, marker) =>

    	this.setState({
    	    activeMarker: marker,
    	    selectedPlace: props,
    	    showingInfoWindow: true
    }, ()=>{
    	//console.log(props)
    });

  	onInfoWindowClose = () =>
    	this.setState({
      	activeMarker: null,
    	showingInfoWindow: false
    });

	onMapClicked = () => {
		if (this.state.showingInfoWindow)
	    	this.setState({
	    	  activeMarker: null,
	    	  showingInfoWindow: false
	    	});
	};

	componentWillMount(){
		this.geo();
	}

    render() {
		const {points, zoomMap} = this.props;
		const {coords} = this.props || { "lat" : this.state.lat , "long" : this.state.lng } ;
		//console.log(coords)
        const style = {
		  	width: this.props.size.width,
		  	height: this.props.size.height,
		}


		if (!this.props.loaded) return <div>Cargando...</div>;
        return <React.Fragment>  
        	<Map
	          	google={this.props.google}
	          	initialCenter = {{
            		lat : coords.lat,
           			lng : coords.long,
        		}}
	          	onClick={this.onMapClicked}
        		onReady={this.onMapReady}
	          	style={style}
	          	zoom={zoomMap}
	          	>
	          	{
	          		this.props.points.length > 0  &&
		          		points.map((point)=>(

			        	<Marker
			        	 	key={point.id}
					    		property={point}
					   	 		position={{
					   	 			lat: point.lat,
				            		lng: point.lng
					   	 		}} 
					   	 		onClick={this.onMarkerClick}
					   	 		icon = {`../../../static/img/ubicacion2Inmobilife.png`}
					   	/>
				   		))
			   	}	
			    {
				   this.props.points.length >0 &&
			   		
				   		<InfoWindow
		          			marker  = {this.state.activeMarker}
		          			onClose = {this.onInfoWindowClose}
		          			visible = {this.state.showingInfoWindow}>
								<div>
									<PropertyCardStyleThree property={this.state.selectedPlace.property}/>
								</div>
		        		</InfoWindow>	
			   	}
			   	   	
			</Map>
			   	}
        </React.Fragment>
    }
}

export default GoogleApiWrapper({
	apiKey: ('AIzaSyAWJdzJwjjscnV_mGqvHaUfKVuJ2HdPudc'),
})(MapProperties);
