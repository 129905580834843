/*FontAwensome*/
import { library }         from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExpandAlt,
         faVectorSquare} from '@fortawesome/pro-light-svg-icons'
library.add(faExpandAlt,
            faVectorSquare);

import {Link} from '../routes'
import slug from '../helpers/slug'

export default class PropertyCardStyleTwo extends React.Component {

    renderElement(files){
        const url_img  = `${process.env.API_HOST}image/`;
        if(files.length > 1)
            return url_img+files[0].id
        return  '/static/img/imagen_no_disponible.png'
    }
    render() {
        const { property } = this.props;
        const url = slug(property.name);

        return <div className="col text-center">            
            <div className="card clearfix mb-3 inner-box">
                <div className="image-box box">              
                    <picture className="card-img-top" >  
                        <source srcset={this.renderElement(property.files)+"_sm"} media="(max-width: 575px)"/>
                        <source srcset={this.renderElement(property.files)+"_md"} media="(min-width: 576px) and (max-width: 767px)"/>
                        <img srcset={this.renderElement(property.files)+"_md"}/>
                    </picture>
                    <div className="ribbon">
                        {property.category.name}
                    </div>
                    <Link route="propiedad" params={{
                        slug: url,
                        id: property.id
                        
                    }}>
                        <a  className="read-more-link">
                            Leer más
                        </a>
                    </Link>
                </div>
                <div className="card-body mb-1">
                    <h3 className="card-title">{property.name}</h3>
                    <div className="price">
                        {parseInt(property.price).toLocaleString('en-US', {style: 'currency', currency: 'USD'})}  MXN
                    </div>
                    <p className="card-text">{property.city+", "+property.state}</p>
                    <ul className="specs-list clearfix  pt-3 card-movil">
                        <li>
                            <div className="outer">
                                <div className="icon p-2">
                                    <span className="fa fa-expand">
                                        <FontAwesomeIcon 
                                            icon={['fal','expand-alt']} 
                                            className="mr-2 green-text"
                                        /> Terreno
                                    </span>
                                </div>
                                <div className="info">
                                    {property.ground} mts2
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="outer">
                                <div className="icon p-2">
                                    <span className="fa fa-expand">
                                        <FontAwesomeIcon 
                                            icon={['fal','vector-square']} 
                                            className="mr-2 green-text"
                                        /> Construcción
                                    </span>
                                </div>
                                <div className="info">
                                    {property.building} mts2
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <style jsx>{`               
                .image-box{
                    position:relative;
                    height: 300px;
                }

                .image-box img{
                    position:relative;
                    display:block;
                    width:100%;
                }
                @media screen and (max-width: 437px) {
                    .card-movil{
                        padding-left: 20px!important;
                        padding-right:20px!important;
                    }
                    .image-box{
                        position:relative;
                        height: 200px;
                    }
                }
                .card-movil{
                    padding-left: 0.25rem ;
                    padding-right: 0.25rem ;
                }
                .cad-slider{
                    margin: 5%;
                }  

                .image-box .ribbon{
                    position:absolute;
                    top:20px;
                    right:-10px;
                    line-height:24px;
                    padding:5px 10px 5px 30px;
                    text-align:right;
                    color:#ffffff;
                    background:#ff9a24;
                    font-size:14px;
                    text-transform:capitalize;
                }

                .image-box .ribbon:after{
                    content:'';
                    position:absolute;
                    right:0px;
                    top:100%;
                    border:5px solid transparent;
                    border-left:5px solid #9e5705;
                    border-top:5px solid #9e5705;
                } 

                .inner-box{
                    position:relative;
                    display:block;
                    margin-bottom:1px;
                    border:1px solid #e0e0e0;
                    -webkit-transition:all 500ms ease;
                    -ms-transition:all 500ms ease;
                    transition:all 500ms ease;      
                }

                .inner-box:hover{
                    border-color:#09a223;
                }

                .inner-box:hover .read-more-link {
                    opacity: 1;
                }
                box img{
                width: 100%;
                height: auto;
                display: flex!important;
                }
                @supports(object-fit: cover){
                    .box img{
                    height: 100%;
                    object-fit: cover;
                    object-position: center center;
                    }
                }
                .read-more-link {
                    position: absolute;
                    left: 0px;
                    bottom: 0px;
                    width: 100%;
                    padding: 13px 15px;
                    text-align: center;
                    background: #009345;
                    opacity: 0;
                    color: #ffffff;
                    font-size: 15px;
                    text-transform: capitalize;
                    line-height: 24px;
                    -webkit-transition: all 500ms ease;
                    -ms-transition: all 500ms ease;
                    transition: all 500ms ease;
                }
                .read-more-link:hover{
                    color: #ffffff!important;
                }   
                .price {
                    font-size: 18px;
                    color: #099f22;
                    font-weight: 600;
                    line-height: 1.4em;
                    margin-bottom: 15px;
                }                      
            `}</style>  
        </div>
    }
}
