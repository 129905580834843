/*React*/
import React, { Component } from 'react'

import Head   from 'next/head';
import {Link} from '../../routes'
import slug   from '../../helpers/slug';


import { library }         from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faFacebookSquare, faInstagram}  from '@fortawesome/fontawesome-free-brands';
import {
	faBars,
	faHome,
	faBuilding,
	faPhone,
	faHandshake,
	faMapMarkedAlt
} from '@fortawesome/pro-regular-svg-icons';


library.add(faBars, faHome, faBuilding, faPhone, faHandshake, faMapMarkedAlt );


/*Bootstrap css*/
import 'bootstrap/dist/css/bootstrap.css';
import '../../src/mdb/css/mdb.css';
/*Semantic*/
import 'semantic-ui-css/semantic.min.css';
/* Own css */
import "./Layout.css";


/*Semantic React*/
import { 
  	Menu,
  	Segment,
	Sidebar
} from 'semantic-ui-react'

/*Bootstrap React*/
import {
	Collapse,
	Navbar,
	NavbarToggler,
	NavbarBrand,
	Nav,
	NavItem,

} from 'reactstrap';

export default class Layout extends Component {
  	state = { visible: false }
	
  	handleHideClick   = () => this.setState({ visible: false })
  	handleShowClick   = () => this.setState({ visible: true })
  	handleSidebarHide = () => this.setState({ visible: false })
  	
  	constructor(props) {
	    super(props);
	    this.toggle = this.toggle.bind(this);
	    this.state = {
	        isOpen: false
	    };
    }
    toggle() {
	    this.setState({
	        isOpen: !this.state.isOpen
	    });
    }
  	render() {
	    const { visible }  = this.state
	    const { children } = this.props

	    return (
		    <React.Fragment>
				<Head>
					<title>{this.props.subtitle} Inmobilife </title>
					<link rel='shortcut icon' type='image/x-icon' href='/static/img/icono2Inmobilife.png' />
					<meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no"/>
					<meta http-equiv="x-ua-compatible" content="ie=edge"/>
					<meta charset="utf-8"/>
				</Head>
		        <div className="white-skin ">
			        <Sidebar.Pushable as={Segment}>
			          	<Sidebar
				            as={Menu}
				            animation='overlay'
				            icon='labeled'
				            inverted
				            onHide={this.handleSidebarHide}
				            vertical
				            visible={visible}
				            width='thin'>
				            <Link prefetch route="/">
				        		<a href="" className="logo-top">
				              		<img className="logo" 
				              			 src="/static/img/inmobilife.png"/>
				            	</a>
				            </Link>
				            <Link prefetch route="/">
				            	<a href="" className="item">
					              	<FontAwesomeIcon icon={['far','home']} size="2x"/>
					              	<p>Inicio</p>
				            	</a>
				            </Link>

				            <Link  prefetch route="propiedades" params={{
				                    page: 1,
				                    slugType: slug('todo'),
				                }}>
					            <a href="" className="item">
					              	<FontAwesomeIcon icon={['far','building']} size="2x"/>
									<p>Propiedades</p>
					            </a>
					        </Link>	
				            <Link prefetch route="/contacto">
					            <a href="" className="item">
									<FontAwesomeIcon icon={['far','phone']} rotation={90} size="2x"/>
									<p>Contacto</p>
					            </a>	
					        </Link>	
							
							<Link prefetch route="/aviso-de-privacidad">
								<a href="" className="item">
									Aviso de privacidad
								</a>	
							</Link>	
										      
					        <div className="mt-2">
						        <a href="https://www.facebook.com/inmobilifepage/" target="_blank"  className="text-white px-2 pt-3">
	                  	  			<FontAwesomeIcon icon={['fab','facebook-square']} size="2x" />
	                  	  		</a>
	                  	  		<p className="text-white">facebook</p>
                  	  		</div>
                  	  		<div className="mt-2">		                          	  
	                  	  		<a href="https://www.instagram.com/inmobilife/" target="_blank"  className="text-white px-2 pt-3">
	                  	  			<FontAwesomeIcon icon={['fab','instagram']} size="2x" />
	                  	  		</a>
	                  	  		<p className="text-white">instagram</p>
                  	  		</div>		            
			          	</Sidebar>

			          	<Sidebar.Pusher dimmed={visible} >
				            <header className="pb-2">
				            	{/*navbar*/}
				                <Navbar className="navbar fixed-top navbar-toggleable-md navbar-expand-lg scrolling-navbar double-nav">
				                    {/*boton for SideNav, 962px*/}
				                    <div className="float-left">
				                        <a onClick={this.handleShowClick} className="button-collapse">
				                            <FontAwesomeIcon icon={['far','bars']} />
				                        </a>
				                    </div>			                    

				                    {/*Title and logo*/}                        
				                    <NavbarBrand  className="breadcrumb-dn mr-auto col-3">
				                        <img className="logo" src="/static/img/inmobilife.png"/>
				                    </NavbarBrand>
				                    
				                    <NavbarToggler onClick={this.toggle} />
				                    <Collapse isOpen={this.state.isOpen} navbar>
				                    	{/*Menu*/}
				                        <Nav className="nav navbar-nav nav-flex-icons ml-auto" navbar>
				                            {/*item nav*/}
				                            <NavItem className="nav-item">
				                            	<Link route="/" prefetch>
					                                <a className="nav-link dropdown">
					                                    <FontAwesomeIcon icon={['far','home']} />
					                                    <span className="clearfix d-none d-sm-inline-block">
					                                        &nbsp; Inicio
					                                    </span>
					                                </a>
					                            </Link>
				                            </NavItem>
				                            
				                            <NavItem className="nav-item">
				                            	<Link route="propiedades" params={{
				                                    page: 1,
				                                    slugType: slug('todo'),
				                                }} prefetch>
					                                <a className="nav-link dropdown">
					                                    <FontAwesomeIcon icon={['far','building']} />
					                                    &nbsp; Propiedades
					                                </a>
					                            </Link>
				                            </NavItem>
				                            {/*item nav*/}
				                            <NavItem className="nav-item">
				                            	<Link prefetch route="/contacto">
					                                <a className="nav-link dropdown">
					                                    <FontAwesomeIcon icon={['far','phone']} rotation={90}/>
					                                    <span className="clearfix d-none d-sm-inline-block">
					                                        &nbsp; Contacto
					                                    </span>
					                                </a>
					                            </Link>
				                            </NavItem>
											<NavItem className="nav-item">
												<Link prefetch route="/aviso-de-privacidad">
													<a  className="nav-link dropdown">
														&nbsp; Aviso de privacidad
													</a>	
												</Link>
											</NavItem>
				                            <NavItem className="nav-item">
										        <a href="https://www.facebook.com/inmobilifepage/" target="_blank"  className=" px-2 pt-3">
					                  	  			<FontAwesomeIcon icon={['fab','facebook-square']} size="2x" />
					                  	  		</a>
					                  	  	</NavItem>
				                  	  		<NavItem className="nav-item">	                          	  
					                  	  		<a href="https://www.instagram.com/inmobilife/" target="_blank"  className=" px-2 pt-3">
					                  	  			<FontAwesomeIcon icon={['fab','instagram']} size="2x" />
					                  	  		</a>
				                            </NavItem>
				                        </Nav>
				                    </Collapse>
				                    <NavbarBrand  className="">
				                        <img className="logo-movil logo" src="/static/img/inmobilife.png"/>
				                    </NavbarBrand>
				                </Navbar>
				                {/*navbar*/}
				            </header>

				        	{/*main*/}
				            <main> 
				                { children }
				            </main>     
				            <footer className="page-footer font-small f-green none">
			                    <div className="f-lime">
			                      	<div className="container">
			                      	  	<div className="row py-4 d-flex align-items-center">
					                        <div className="col-md-6 col-lg-5 text-center text-md-left mb-4 mb-md-0">
					                            <h6 className="text-footer mb-0">
					                            	¡Contactanos en nuestras redes sociales!!
					                            </h6>
					                        </div>
				                          	<div className="col-md-6 col-lg-7 text-center text-md-right">				                          	  	
			                          	  		<a href="https://www.facebook.com/inmobilifepage/" target="_blank"  className="px-2">
			                          	  			<FontAwesomeIcon icon={['fab','facebook-square']} size="3x" />
			                          	  		</a>			                          	  
			                          	  		<a href="https://www.instagram.com/inmobilife/" target="_blank"  className="px-2">
			                          	  			<FontAwesomeIcon icon={['fab','instagram']} size="3x" />
			                          	  		</a>				                          	  	
				                          	</div>
			                      	  	</div>
			                      	</div>
			                    </div>
			                    
			                    <div className="container text-center text-md-left mt-5">
			                      	<div className="row mt-3"> 
				                        <div className="col-md-4 col-lg-4 mb-4">
				                        	<img src="/static/img/inmobilifeBlanco.png"></img>
											
				                            <p>
				                                "Protegemos tu patrimonio, respaldamos tu inversión"
				                            </p>
											
											<p>
												Inversiones, 
												Valuación, 
												Transacciones Inmobiliarias, 
												Manejo de activos inmobiliarios, 
											</p>	
				                        </div>
										<div className="col-md-4 col-lg-4 d-none-xs  mb-4">
				                            <h6 className="px-lg-5  title-footer font-weight-bold pb-3">
				                                Links
				                            </h6>
											<ul>
									            <li className="px-lg-5 py-2">
									            	<Link route="/">
									            		<a href="" className="font-16">
										              		<FontAwesomeIcon icon={['far','home']} size="lg"/>
										              	    &nbsp; Inicio
									            		</a>
									            	</Link>
									            </li>

									            <li className="px-lg-5 py-2">
									            	<Link  route="propiedades" params={{
									                	    page: 1,
									                    	slugType: slug('todo'),
									                	}}>	
										            	<a href="" className="font-16">
										        	      	<FontAwesomeIcon icon={['far','building']} size="lg"/>
															&nbsp; Propiedades
										            	</a>
										        	</Link>	
										       	</li>
									            <li className="px-lg-5 py-2">
													<Link route="/contacto">
										            	<a href="" className="font-16">
															<FontAwesomeIcon icon={['far','phone']} rotation={90} size="lg"/>
															&nbsp; Contacto
											            </a>	
										        	</Link>	
										        </li>
												<li className="px-lg-5 py-2">
									            	<Link route="/aviso-de-privacidad">
										            	<a href="" className="font-16">															
															&nbsp; Aviso de privacidad
											            </a>	
										        	</Link>	
										        </li>	
											</ul>
				                        </div>
				                       
				                        <div className="col-md-4 col-lg-4 mb-4">
				                        	<h6 className="title-footer font-weight-bold pb-3">
				                        		Contacto
				                        	</h6>
											<ul className="row">
												<li className="row col-12 pt-3">
													<p className="col pt-2">
														<FontAwesomeIcon 
															icon={['far','map-marked-alt']} 
															size="lg"
														
														/>
														
														&nbsp;&nbsp;&nbsp;&nbsp;
														Tulipán #1002, int 102. Cd. De los niños. Zapopan.
													</p>
													
												</li>
												<li className="row col-12 pt-3">
													<p className="col pt-2">
														<FontAwesomeIcon 
															icon={['far','phone']} 
															size="lg"
														/> 
													
														&nbsp;&nbsp;&nbsp;&nbsp;33 2005 0949
													</p>
													
												</li>
											</ul>
				                        </div>
			                      	</div>
			                    </div>
			                    <div className="footer-copyright text-center py-3">
								    <p className="font-13">
									    &copy; 2019 &nbsp; 
										<a href route="/">
											inmobilife
										</a> │ 
										Inmobilife &reg; es una marca registrada
									</p>
									<a href="http://grupotb.com.mx"  target="_blank">
										Desearrollo web por: GrupoTB
									</a>
			                    </div>
			                </footer>
			          	</Sidebar.Pusher>
			        </Sidebar.Pushable>
		      	</div>
		    </React.Fragment>
	    )
  	}
}