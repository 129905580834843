import React, { Fragment } from "react";

import {
	Button,
	Modal, 
    ModalHeader, 
    ModalBody, 
    ModalFooter,
} from "reactstrap";

/*Bootstrap css*/
import 'bootstrap/dist/css/bootstrap.css';
import '../../src/mdb/css/mdb.css';




import { library }         from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearchLocation} from '@fortawesome/pro-regular-svg-icons'
library.add(faSearchLocation);
/* Own Components */
import AGREEMENT_TYPES from "../../constants/agreement_types";
import CATEGORIES 	   from "../../constants/category_constants";

import {
	Form,
} from 'semantic-ui-react';

import InputType     from "./InputType";
import InputMap      from "./InputMap"
import InputCategory from "./InputCategory"


class SearchFiltersModal extends React.Component {

	ParseIntIfNotUndefined = arg => arg == undefined || arg == null || typeof arg !== "string"
        ? null
		: parseInt(arg);

    constructor(props){
        super(props);

        this.state = {
            params : {
                type_id  : this.props.paramsSearch.type_id,
				category : this.ParseIntIfNotUndefined(this.props.paramsSearch.category),
            }
		};
		
	}
	updateType = type_id => {
        // ground_max = this.state.params.ground_min > ground_max ? 0 : ground_max;
        this.setState({
            params: {
                ...this.state.params,
                type_id: type_id == 0 ? null : type_id,
            }
        }, ()=>{
              //console.log(this.state.params)
        });
	};
	updateCategory = category => {
        // ground_max = this.state.params.ground_min > ground_max ? 0 : ground_max;
        this.setState({
            params: {
                ...this.state.params,
                category: category == 0 ? null : category,
            }
        }, ()=>{
            
        });
    };

	render(){
		const props = this.props;
		const {
			type_id,
			category
		} = this.state.params;

		return <Modal {...props}>
				<ModalHeader toggle={props.toggle}>
					
					<FontAwesomeIcon icon={['far','search-location']} size="2x" /> 
					  Filtros de Busqueda 
				</ModalHeader>
				<ModalBody>
					<Form>
						{/*<div className="col-12 px-1 py-2">
							<InputMap
								updateGeolacation = {props.updateGeolacation}
							/>
						<div>
						*/}
						<div className="col-12 px-1 py-2">	
							<InputType 		    	 
								placeholder='Tipo de contrato' 
								search 
								selection 
								options={AGREEMENT_TYPES}
								className="col-12"
								defaultValue={type_id}
								updateType ={this.updateType}
								
							/>
						</div>
						<div className="col-12 px-1 py-2">	
							<InputCategory
								placeholder='Categoría' 
								search 
								selection 
								options={CATEGORIES}
								className="col-12"
								defaultValue={category}
								updateCategory ={this.updateCategory}
								
							/>	 
						</div>
					</Form>	
				</ModalBody>
				<ModalFooter>
                <Button color="secondary" onClick={props.toggle}>
                    Cancelar
                </Button>{' '}
                <Button color="primary" 
                        onClick={()=>{props.buttonSearch(this.state.params); props.toggle();}}
                >
                    Buscar
                </Button>            
            </ModalFooter>
			</Modal> 
	
	}
}

export default SearchFiltersModal;