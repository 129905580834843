import React from "react";
import { Input } from 'semantic-ui-react'

class InputMapChallenger extends Input {
	
	componentDidUpdate(prevProps, prevState){

	}
}

export default InputMapChallenger;
