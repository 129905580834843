
/*FontAwensome*/
import { library }         from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
	faBuilding, 
	faSearchLocation,
	faHomeHeart
} from '@fortawesome/pro-regular-svg-icons'
library.add(
	faBuilding, 
	faSearchLocation,
	faHomeHeart	
);

import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

import {Link} from '../routes'
import PropertyCardStyleOne from  '../components/PropertyCardStyleOne'

export default class MultipleItemCarousel extends React.PureComponent{
	constructor(props) {
		super(props);
	}

	render() {
		const properties = this.props.properties.map(property => (
			<div className="carousel-product" key={property.id}>
				<PropertyCardStyleOne
					key={property.id}
					property={property}
					/>
			</div>
		));
		const settings = {
			 infinite       : true,
			 slidesToShow   : 3,
			 slidesToScroll : 1,
			 autoplay       : true,
			 autoplaySpeed  : 4000,
			 pauseOnHover   : true,
			 responsive     : [
				 {
					 breakpoint : 959,
					 settings   : {
						 infinite       : true,
						 slidesToShow   : 2,
						 slidesToScroll : 1,
						 autoplay       : true,
						 autoplaySpeed  : 4000,
						 pauseOnHover   : true,
					 }
				 },
				 {
					 breakpoint : 769,
					 settings   : {
						 infinite       : true,
						 slidesToShow   : 1,
						 slidesToScroll : 1,
						 autoplay       : true,
						 autoplaySpeed  : 4000,
						 pauseOnHover   : true,
					 },
				 },
			 ],
		};
	    return (
	    	<div className="cad-slider p-5 mb-4">
                <div className="title-style-one left-aligned row">
                    <div className="col-1 mx-3 clearfix d-none d-md-block">
                        <FontAwesomeIcon icon={['far','home-heart']} className="size-icon"/>
                    </div>
                    
                    <div className="col mx-3">
                        <div className="title">
                            <h2>Últimas propiedades agregadas</h2>
                        </div>
                        <div className="sub-title">
                           Conoce nuestra oferta inmobiliaria, mereces el mejor hogar
                        </div>
                    </div>
                </div>
                <div className="col-xs-12 mt-5">
                    <Slider  {...settings} className="mb-1">
                        {properties}
                    </Slider>
                </div>
            </div>
		)
	}
}