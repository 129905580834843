const CATEGORIES = [
    { key: 0,  text: 'todo', value: 0},
    { key: 1,  text: 'agrícolaganaderos', value: 1},
    { key: 2,  text: 'bodegas e Industriales', value: 2},
    { key: 3,  text: 'cabañas', value: 3},
    { key: 4,  text: 'casas', value: 4},
    { key: 5,  text: 'departamentos', value: 5},
    { key: 6,  text: 'desarrollos Horizontales', value: 6},
    { key: 7,  text: 'desarrollos Verticales', value: 7},
    { key: 8,  text: 'edificios', value: 8},
    { key: 9,  text: 'hoteles', value: 9},
    { key: 10, text: 'locales Comerciales', value: 10},
    { key: 11, text: 'oficinas', value: 11},
    { key: 12, text: 'otros', value: 12},
    { key: 13, text: 'parques Industriales', value: 13},
    { key: 14, text: 'rancho', value: 14},
    { key: 15, text: 'terrazas', value: 15},
    { key: 16, text: 'terrenos', value: 16},
    { key: 17, text: 'usos Multiples', value: 17},
    { key: 18, text: 'vacacionales', value: 18},
];

export default CATEGORIES;