import React from "react";

import {
	Dropdown,
} from 'semantic-ui-react';


class InputType extends Dropdown {
	
	componentDidUpdate(prevProps, prevState){
		if (prevState.selectedIndex !== this.state.selectedIndex) {
			this.props.updateType(this.state.selectedIndex);
		}
	}
}

export default InputType;
