import React from "react";

import {
	Dropdown,
} from 'semantic-ui-react';


class InputCategory extends Dropdown {
	
	componentDidUpdate(prevProps, prevState){
		if (prevState.selectedIndex !== this.state.selectedIndex) {

			//console.log(this.state.selectedIndex)
			this.props.updateCategory(this.state.selectedIndex);
		}
	}
}

export default InputCategory;
