import React from "react";

/*FontAwensome*/
import { library }         from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

/* Own components */
import SearchFiltersModal from "./SearchFiltersModal";
import SearchFiltersForm  from "./SearchFiltersForm";

import { faSearchLocation} from '@fortawesome/pro-regular-svg-icons'
library.add(faSearchLocation);


/*TODO: CHECK LATER*/
const renderLabel = label => ({
  color: 'orange',
  content: `${label.text}`,
  icon: 'check',
});

class Search extends React.Component {

	constructor(props){
		super(props);
	}


	state = { 
		searchQuery: '',
		isOpen: false
	}

 	handleChange = (e, { searchQuery, value }) => this.setState({ searchQuery, value });

	handleSearchChange = (e, { searchQuery }) => this.setState({ searchQuery });

    toggle = () => {
        this.setState({
            isOpen: !this.state.isOpen
        });
    };
  	
  	
  	render() {
		const { searchQuery, value } = this.state
	    return (
	    	<React.Fragment>
			    <div className="pt-3 col-md-8 d-none d-md-block">

					<SearchFiltersForm
						handleChange={this.handleChange}
						handleSearchChange={this.handleSearchChange}
						renderLabel={renderLabel}
						searchQuery={searchQuery}
						value={value}
						updateType={this.props.updateType}
						updateCategory={this.props.updateCategory}
						onClick={this.props.buttonSearch}
						updateGeolacation = {this.props.updateGeolacation}
						paramsSearch={this.props.paramsSearch}
					/>
			    </div>
			    <div className="pt-2 col-5 d-md-none d-lg-none ">
					<button
							className="btn btn-outline-white waves-effect mt-06 col px-0 px-sm-2"
							style={{marginTop: '.6rem'}}
							onClick={this.toggle}
						
						>
						Filtros de busqueda &nbsp;
						<FontAwesomeIcon icon={['far','search-location']} size="lg" /> 
					</button>
			    	<SearchFiltersModal 
			    		handleChange={this.handleChange}
						handleSearchChange={this.handleSearchChange}
						renderLabel={renderLabel}
						searchQuery={searchQuery}
						value={value}
						buttonSearch={this.props.buttonSearch}
						isOpen={this.state.isOpen}
                        toggle={this.toggle}
						updateGeolacation = {this.props.updateGeolacation}
						paramsSearch={this.props.paramsSearch}
						
			    	/>
			    </div>
		   	</React.Fragment>
    	);
  	}
}

export default Search;
